import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/element'
import i18n from "./locale";

import './plugins/PaC'
import './plugins/swiper'
import './plugins/axios'
import './assets/css/reset.css'
import './assets/css/flex.css'
import './assets/css/common.css'
import { servicesLoading } from '@/utils/loading'
import VueMeta from 'vue-meta'

// 全局方法挂载
Vue.prototype.$loading = servicesLoading

//回顶部
Vue.prototype.$scrollTo = (x = 0, y = 0, type = 'instant') => {
  window.scrollTo({
    top: x,
    left: y,
    behavior: type // 滚动行为：smooth平滑滚动，instant瞬间滚动，默认值auto，等同于instant
  })
}

Vue.use(VueMeta)
Vue.config.productionTip = false

window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
