<template>
	<div>
		<v-header showStyle="white"></v-header>
		<div class="bg-box">
			<div class="wrap contain-box">

				<div class="main-title f-row-s-c">
					<div class="line"></div>接送机
				</div>

				<!-- <div class="card-box">
				<div class="card-tit f-row-s-c">
					<img src="../../../public/img/order1.png" alt="">类型
				</div>
				<div class="tab-box f-row-s-c">
					<div class="tab" :class="currentTab == item.id ? 'active' : ''" v-for="item in tabsList" :key="item.id"
						@click="changeTabs(item.id)">
						{{ item.title }}
					</div>
				</div>
			</div> -->

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order1.png" alt="">出发地
					</div>
					<div class="address-box">
						<v-address width="634px" @inputValue="inputValueStart" placeholder="请输入出发地详细地址"></v-address>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order1.png" alt="">到达地
					</div>
					<div class="address-box">
						<v-address width="634px" @inputValue="inputValueEnd" placeholder="请输入到达地详细地址"></v-address>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order1.png" alt="">航班信息
					</div>
					<div class="common-inputs" style="width:632px;">
						<el-input placeholder="请输入航班号" v-model="form.airplaneNo"></el-input>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order2.png" alt="">出发时间
					</div>
					<div class="time-box f-row-s-c">
						<div class="common-times f-row-b-c">
							<el-date-picker type="date" v-model="form.startDate" prefix-icon="0" placeholder="请选择出发日期"
								value-format="yyyy-MM-dd" clearable>
							</el-date-picker>
							<i class="el-icon-arrow-down"></i>
						</div>
						<div class="common-times f-row-b-c">
							<el-time-picker v-model="form.startTime" prefix-icon="0" placeholder="请选择出发时间" value-format="HH:mm:ss"
								format="HH:mm" clearable>
							</el-time-picker>
							<i class="el-icon-arrow-down"></i>
						</div>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order3.png" alt="">车型
					</div>
					<div class="car-box">
						<div>
							<el-select class="common-select" v-model="form.carTypeId" placeholder="请选择车型" @change="changeCarType">
								<el-option v-for="item in carList" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="car-tips" v-if="Object.keys(carTypeInfo).length > 0">
							该车型共{{ carTypeInfo.passengerCapacity || 0 }}个座位，最多可携带
							{{ carTypeInfo.luggageBearCapacity || 0 }}件行李
						</div>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order4.png" alt="">平台服务
					</div>
					<div class="service-box">
						<el-checkbox label="举牌接机" v-model="theRaising" :true-label="2" :false-label="1"></el-checkbox>
						<el-checkbox label="宝宝座椅" v-model="theBaby" :true-label="2" :false-label="1"></el-checkbox>
						<el-input-number v-model="babyNum" :min="1" size="medium" style="margin-right:20px;"></el-input-number>
						<el-checkbox label="送机服务" v-model="songRaising" :true-label="2" :false-label="1"></el-checkbox>
					</div>
				</div>

				<div class="operate-box">
					<div v-if="isCalculate" class="price-box f-row-b-c">
						<div class="l-price f-row-s-c">
							服务价格：<span class="nums">￥<span>{{ countPrice }}</span></span>
						</div>
						<div class="r-price f-row-c-c" @click="goBuy">立即出发</div>
					</div>
					<button v-else class="btn" @click="goCalculate">计算价格</button>
				</div>
			</div>

			<!-- 确认订单弹窗 -->
			<vorder-dialog ref="orderChild" curType="5" :isShow="orderDialog" :infos="orderInfo" @closeDialog="closeOrderDialog"
				@confirmPay="confirmPay"></vorder-dialog>

			<!-- 支付二维码弹窗 -->
			<vpay-dialog ref="payChild" :isShow="payDialog" :infos="codeInfo" :payWay="currentPay"
				@closeDialog="closePayDialog"></vpay-dialog>
				
		</div>
	</div>
</template>

<script>
import $api from '@/api/api.js'
export default {
	name: 'transfer',
	data() {
		return {
			//tabs
			currentTab: 0,
			tabsList: [{
				id: 0,
				title: '去机场接您'
			},
			{
				id: 1,
				title: '送您去机场'
			}],

			form: {
				startAddress: '',//出发地
				endAddress: '',//到达地
				airplaneNo: '',//航班号
				startDate: '', //出发日期
				startTime: '', //出发时间
				carTypeId: '', //车型id
			},

			carList: [],//车型列表
			carTypeInfo: {},//选中的车型信息

			theRaising: 1, //举牌接机自定义状态 1不需要 2需要
			songRaising: 1,//送机服务自定义状态 1不需要 2需要
			theBaby: 1, //宝宝座椅自定义状态 1不需要 2需要
			babyNum: 1, //宝宝座椅数量自定义

			isCalculate: false,//是否已计算价格
			countPrice: '0.00',//服务价格

			//显示弹窗--确认订单
			orderDialog: false,
			orderInfo: {},//确认订单信息

			//显示弹窗--扫码支付
			payDialog: false,
			currentPay: '1',//支付方式
			codeInfo: {
				id: '',
				urlCode: '',
			},
		}
	},
	created() {
		this.getCarList()
	},
	methods: {
		//切换类型
		changeTabs(id) {
			this.currentTab = id
		},
		//获取车型列表
		getCarList() {
			$api.listAllCarConfig().then(res => {
				if (res.code == 200) {
					let querys = res.data || []
					this.carList = []
					if (querys && querys.length > 0) {
						querys.forEach(i => {
							this.carList.push({
								label: i.carTypeName,
								value: i.id,
								introduce: i.introduce,
								picPath: i.picPath,
								luggageBearCapacity: i.luggageBearCapacity,
								passengerCapacity: i.passengerCapacity
							})
						})
					}
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//选择车型
		changeCarType(e) {
			this.carTypeInfo = this.carList.find((item) => item.value == e)
		},
		//赋值出发地地址
		inputValueStart(e) {
			this.form.startAddress = e;
		},
		//赋值结束地地址
		inputValueEnd(e) {
			this.form.endAddress = e;
		},
		//计算价格
		goCalculate() {
			if (!this.form.startAddress) {
				this.$message.error('请输入出发地地址')
				return
			}
			if (!this.form.endAddress) {
				this.$message.error('请输入到达地地址')
				return
			}
			if (!this.form.startDate) {
				this.$message.error('请选择出发日期')
				return
			}
			if (!this.form.startTime) {
				this.$message.error('请选择出发时间')
				return
			}
			if (Object.keys(this.carTypeInfo).length == 0) {
				this.$message.error('请选择车型')
				return
			}
			const loading = this.$loading('', '价格计算中...', true, 'el-icon-loading', 'rgba(0, 0, 0, 0.8)')
			let dataParams = {}
			dataParams = {
				setOutAddress: this.form.startAddress,
				arriveOutAddress: this.form.endAddress,
				appointmentTime: this.form.startDate + ' ' + this.form.startTime,
				cardId: this.form.carTypeId,
				theRaising: this.theRaising,
				songRaising: this.songRaising,
				theBaby: this.theBaby == 1 ? 0 : this.babyNum,//宝宝座椅数量
			}
			$api.initOrderOneTwoMoney(dataParams).then(res => {
				loading.close();
				if (res.code == 200) {
					this.$message.success('计算成功')
					this.countPrice = res.data.orderAllMoney || '0.00'
					this.isCalculate = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//立刻出发--打开确认订单
		goBuy() {
			this.orderInfo = {}
			this.orderInfo = {
				orderSource: 'PC',
				orderTypeCode: 'PICKUP_SERVICES',
				// aircraft: this.currentTab, //0接机 1送机
				setOutAddress: this.form.startAddress,
				arriveOutAddress: this.form.endAddress,
				airplaneNo: this.form.airplaneNo,
				appointmentTime: this.form.startDate + ' ' + this.form.startTime,
				cardId: this.form.carTypeId,
				cardName: this.carTypeInfo.label,
				theRaising: this.theRaising,
				songRaising: this.songRaising,
				theBaby: this.theBaby == 1 ? 0 : this.babyNum,
				allMoney: this.countPrice,
			}
			this.orderDialog = true
		},
		//关闭弹窗--确认订单
		closeOrderDialog() {
			this.orderDialog = false
		},
		//关闭弹窗--扫码支付
		closePayDialog() {
			this.payDialog = false
		},
		//确认弹窗--打开扫码支付弹窗
		confirmPay(backObj) {
			this.currentPay = backObj.payWay
			let params = {
				orderId: backObj.orderId,
				orderType: 2, //1企业 2用户
			}
			//获取支付信息
			$api[backObj.payWay == 1 ? 'setzfbPay' : 'setWxPay'](params).then(res => {
				if (res.code == 200) {
					this.codeInfo = res.data || {}
					this.$refs.payChild.getOpenDialog()
					this.payDialog = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.tab-box {
	border-radius: 4px;
	overflow: hidden;

	.tab {
		width: 254px;
		height: 50px;
		line-height: 50px;
		font-size: 18px;
		color: var(--theme-color);
		text-align: center;
		border: 1px solid var(--theme-color);
		cursor: pointer;
		user-select: none;
	}

	.active {
		color: #F3F3F3;
		background: var(--theme-color);
	}
}

.contain-box {
	padding: 30px 46px;
}

.card-tit {
	padding: 30px 0 20px;
	font-size: 18px;
	color: #333;

	img {
		margin-right: 8px;
		max-width: 18px;
		width: auto;
	}
}

.car-box {
	.car-tips {
		display: inline-block;
		margin-top: 20px;
		padding: 0 20px;
		line-height: 28px;
		font-size: 14px;
		color: #FE4E00;
		background: #fffaf7;
		border-radius: 4px;
	}
}

.operate-box {
	padding: 50px 0 100px;
	width: 632px;
}

.price-box {
	height: 74px;
	background: #f3f3f3;
	border-radius: 4px;
	overflow: hidden;

	.l-price {
		padding-left: 18px;
		font-size: 18px;
		color: #333;

		.nums {
			font-size: 14px;
			color: #fa692a;

			span {
				font-weight: bold;
				font-size: 20px;
			}
		}
	}

	.r-price {
		width: 220px;
		height: 100%;
		font-size: 22px;
		color: #fff;
		background: var(--theme-color);
		cursor: pointer;
	}
}
</style>