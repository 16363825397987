<template>
    <div>
        <el-dialog width="876px" :visible.sync="isShow" :show-close="false">
            <!-- 头部区 -->
            <div class="dialog-top f-row-b-c" slot="title">
                <span class="titles">{{ $t('Order19') }}</span>
                <i class="closes el-icon-close" @click="handleClose"></i>
            </div>
            <!-- 内容区 -->
            <div class="dialog-form" :class="languageVal == 'en' ? 'small' : ''">
                <div v-if="curType == 1 || curType == 2 || curType == 3" class="other-title f-row-s-c one-txt-cut">
                    {{ infos.desName || '' }}
                </div>
                <div class="items f-row-s-c">
                    <div class="labels">{{ $t('Order21') }}：</div>
                    <div class="values">{{ typeName[curType] }}</div>
                </div>
                <div v-if="curType == 4 || curType == 5 || curType == 6" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order3') }}：</div>
                    <div class="values one-txt-cut">{{ infos.setOutAddress || '' }}</div>
                </div>
                <div v-if="curType == 4 || curType == 5" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order5') }}：</div>
                    <div class="values one-txt-cut">{{ infos.arriveOutAddress || '' }}</div>
                </div>
                <div v-if="curType == 5" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order22') }}：</div>
                    <div class="values one-txt-cut">{{ infos.airplaneNo || '' }}</div>
                </div>
                <div v-if="curType == 4 || curType == 5 || curType == 6" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order13') }}：</div>
                    <div class="values one-txt-cut">{{ infos.appointmentTime || '' }}</div>
                </div>
                <div v-if="curType == 4 || curType == 5 || curType == 6" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order23') }}：</div>
                    <div class="values">{{ infos.cardName || '' }}</div>
                </div>
                <div class="items f-row-s-c">
                    <div class="labels">{{ $t('Order24') }}：</div>
                    <div class="values f-row-s-c">
                        <div class="inputs">
                            <el-input v-model="form.linkName" :placeholder="$t('Order25')" clearable></el-input>
                        </div>
                        <div class="inputs">
                            <el-input v-model="form.linkPhone" :placeholder="$t('Order26')" clearable></el-input>
                        </div>
                    </div>
                </div>
                <div v-if="curType == 2" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order27') }}：</div>
                    <div class="values f-row-s-c">
                        <el-radio-group v-model="form.curYu" class="f-row-s-c">
                            <el-radio label="1">{{ $t('Order28') }}</el-radio>
                            <el-radio label="2">{{ $t('Order29') }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div v-if="curType == 2 && form.curYu == 2" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order30') }}：</div>
                    <div class="values f-row-s-c">
                        <div class="inputs">
                            <el-input v-model="form.beName" :placeholder="$t('Order31')" clearable></el-input>
                        </div>
                        <div class="inputs">
                            <el-input v-model="form.bePhone" :placeholder="$t('Order32')" clearable></el-input>
                        </div>
                        <div class="inputs">
                            <el-input v-model="form.beEmail" :placeholder="$t('Order33')" clearable></el-input>
                        </div>
                    </div>
                </div>
                <div v-if="curType == 1 || curType == 2" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order53') }}：</div>
                    <div class="values f-row-s-c">
                        <el-input-number v-model="form.spcNum" :min="1" :label="$t('Order34')" style="width:240px;"
                            @change="changeNum"></el-input-number>
                    </div>
                </div>
                <div v-if="curType == 1 || curType == 2" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order56') }}：</div>
                    <div class="values f-row-s-c">
                        <el-select class="inputs" v-model="form.specId" :placeholder="$t('Order35')" @change="changeSpec">
                            <el-option v-for="item in infos.specList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div v-if="curType == 1 || curType == 2" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order54') }}：</div>
                    <div class="values common-times f-row-b-c" style="width:240px;height:50px;">
                        <el-date-picker v-model="form.serviceTime" type="datetime" prefix-icon="0"
                            :placeholder="$t('Order36')" value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                        <i class="el-icon-arrow-down"></i>
                    </div>
                </div>
                <div v-if="curType == 3" class="items f-row-s-c">
                    <div class="labels">{{ $t('Order37') }}：</div>
                    <div class="values">
                        <el-input-number v-model="form.dayNum" :min="1" size="small"></el-input-number>
                    </div>
                </div>
                <div class="items f-row-s-c">
                    <div class="labels">{{ $t('Order38') }}：</div>
                    <div class="values f-row-s-c">
                        <template v-if="couponList && couponList.length > 0">
                            <el-radio-group v-model="form.curCoupon" class="f-row-s-c f-w" @input="changeCoupon">
                                <div class="radio-item" v-for="item in couponList" :key="item.id">
                                    <el-radio class="f-row-s-c" :label="item.id">
                                        <div class="coupon-item flex">
                                            <div class="lefts f-row-c-c">
                                                <div class="price">￥<span>{{ item.couponMoney || 0.00 }}</span></div>
                                            </div>
                                            <div class="rights f-col-b-s">
                                                <div class="name one-txt-cut">{{ item.couponTitle || '' }}</div>
                                                <div class="condition" v-if="item.couponTopMoney">
                                                    {{ $t('Order51') }}{{ item.couponTopMoney }}{{ $t('Order52') }}
                                                </div>
                                                <div class="condition" v-else>{{ $t('Order39') }}</div>
                                                <div class="time">{{ $t('Order40') }}：{{ item.effectiveTime }}</div>
                                            </div>
                                        </div>
                                    </el-radio>
                                </div>
                            </el-radio-group>
                        </template>
                        <template v-else>
                            <div>{{ $t('Tips6') }}</div>
                        </template>
                    </div>
                </div>
                <div class="items f-row-s-c">
                    <div class="labels">{{ $t('Order41') }}：</div>
                    <div class="values f-row-s-c">
                        <el-radio-group v-model="form.curPay" class="f-row-s-c">
                            <el-radio :label="1">{{ $t('Order42') }}</el-radio>
                            <el-radio :label="2">{{ $t('Order43') }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <!-- <div class="f-row-s-c">
                    <div class="items f-row-s-c" style="margin-right: 20px;">
                        <div class="labels">{{$t('Order50')}}：</div>
                        <div class="values">
                            <el-select v-model="unitName" placeholder="请选择" @change="changeMoney" class="common-select"
                                style="width:192px;height:50px;">
                                <el-option v-for="item in moneyUnitList" :key="item.configId" :label="item.remark"
                                    :value="item.configId">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="items f-row-s-c">
                        <div class="labels">{{$t('Order57')}}：</div>
                        <div class="values" style="color: #fb6729;">{{ unitPrice }}</div>
                    </div>
                </div> -->

                <div class="f-row-s-c">
                    <div class="items f-row-s-c" style="margin-right: 20px;">
                        <div class="labels">{{ $t('Order44') }}：</div>
                        <div class="values">
                            <span v-if="curType == 3">{{ unitName }}{{ (carPrice * unitPrice).toFixed(2)
                            }}/{{ $t('Order45') }}</span>
                            <span v-else>{{ unitName }}{{ (infos.allMoney * unitPrice).toFixed(2) }}</span>
                        </div>
                    </div>
                    <div class="items f-row-s-c" style="margin-right: 20px;">
                        <div class="labels">{{ $t('Order46') }}：</div>
                        <div class="values" style="color: #fb6729;">-{{ unitName }}{{ (vipPrice * unitPrice).toFixed(2) }}
                        </div>
                    </div>
                    <div class="items f-row-s-c">
                        <div class="labels" style="flex:0 0 120px;">{{ $t('Order47') }}：</div>
                        <div class="values" style="color: #fb6729;">-{{ unitName }}{{ (Number(couponInfo.couponMoney) *
                            unitPrice).toFixed(2) }}</div>
                    </div>
                </div>

                <div class="items f-row-s-c">
                    <div class="labels">{{ $t('Order48') }}：</div>
                    <div class="values" style="color: #fb6729;">{{ unitName }}{{ (realPrice * unitPrice).toFixed(2) }}</div>
                </div>

            </div>
            <!-- 底部区 -->
            <div class="dialog-foot f-row-c-c" slot="footer">
                <el-button type="primary" @click="confirmSubmit">{{ $t('Order49') }}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'Vorder-dialog',
    props: {
        //类型
        curType: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        infos: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            languageVal: '',
            form: {
                linkName: '',//联系人姓名
                linkPhone: '',//联系人电话
                beName: '',//被预订人姓名
                bePhone: '',//被预订人电话
                beEmail: '',//被预订人邮箱
                serviceTime: '',//服务日期
                curYu: '1',//帮人预定 1否 2是
                dayNum: 1,//租赁天数
                curPay: 1,//支付方式 1支付宝 2微信
                curCoupon: '',//当前优惠券

                spcNum: 1,//数量
                specId: '',//规格id
            },
            vipDiscount: 1,//会员折扣
            curSpecInfo: {},//当前规格信息

            couponList: [
                // {
                //     id: '1',
                //     couponMoney: '12.00',
                //     couponTitle: '1小时优惠券',
                //     couponTopMoney: '10',
                //     effectiveTime: '2021-10-10 10:10:00'
                // },
            ],//优惠券列表

            //选中的优惠券信息
            couponInfo: {
                id:'',
                couponTitle: '',
                couponMoney: 0,
                couponTopMoney: '',//满多少送多少
                couponTopAUDMoney: '0.00',
                couponAUDMoney: '0.00',
            },

            unitPrice: '1',//货币单价 id
            unitName: this.$i18n.locale == 'en' ? '$' : '￥', //货币名称 name
            moneyUnitList: [],//货币单位列表

            //自定义显示类名
            typeName: {
                '1': this.$t('OT1'),
                '2': this.$t('OT2'),
                '3': this.$t('OT3'),
                '4': this.$t('OT4'),
                '5': this.$t('OT5'),
                '6': this.$t('OT6'),
            },

            CNYvipPrice: '', //会员折扣
            CNYrealPrice: '', //实付价
        }
    },
    computed: {
        // 产品价格--汽车租赁
        carPrice() {
            return (parseInt(this.infos.allMoney) * this.form.dayNum).toFixed(2) || 0
        },
        // 会员折扣
        vipPrice() {
            this.CNYvipPrice = (Number(this.infos.CNYallMoney) - Number(this.infos.CNYallMoney) * parseFloat(
                this.vipDiscount)).toFixed(2) || 0
            return (Number(this.infos.allMoney) - Number(this.infos.allMoney) * parseFloat(this.vipDiscount)).toFixed(2) ||
                0
        },
        //实付价
        realPrice() {
            let money = 0
            if (this.$i18n.locale == 'en') {
                money = this.couponInfo.couponAUDMoney || 0
            } else {
                money = this.couponInfo.couponMoney || 0
            }
            this.CNYrealPrice = (Number(this.infos.CNYallMoney) - Number(this.CNYvipPrice) - Number(this.couponInfo.couponMoney || 0))
                .toFixed(2) || 0
            return (Number(this.infos.allMoney) - Number(this.vipPrice) - Number(money)).toFixed(2) || 0
        },
    },
    created() {
        if (localStorage.getItem('language')) {
            this.languageVal = localStorage.getItem('language');
        }
        if (localStorage.getItem('USER_TOKEN')) {
            this.getUserInfo()
            this.getCouponList()
            this.getMoneyUnitList()
        }
    },
    methods: {
        //获取个人信息--会员折扣
        getUserInfo() {
            $api.getHighImportInfo().then(res => {
                if (res.code == 200) {
                    this.vipDiscount = res.data.vipDiscount || ''
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取优惠券列表
        getCouponList() {
            $api.userCouponList({
                pageNum: 1,
                pageSize: 999
            }).then(res => {
                if (res.code == 200) {
                    this.couponList = res.rows || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取货币单位列表
        getMoneyUnitList() {
            $api.huiLvList().then(res => {
                if (res.code == 200) {
                    this.moneyUnitList = res.data || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //选择货币单位
        changeMoney(e) {
            let obj = this.moneyUnitList.find((ele, i) => e == ele.configId)
            if (obj) {
                this.unitName = obj.remark.slice(0, 1)
                this.unitPrice = obj.configValue
            }
        },
        //选择优惠券
        changeCoupon(id) {
            let obj = this.couponList.find((item) => id == item.id)
            this.couponInfo = obj || {}
        },
        //选择规格
        changeSpec(e) {
            this.form.specId = e
            this.curSpecInfo = this.infos.specList.find((item, index) => item.id == e)
            if (this.$i18n.locale == 'en') {
                //澳元
                this.infos.allMoney = this.form.spcNum * this.curSpecInfo.spcAUDPrice
            } else {
                this.infos.allMoney = this.form.spcNum * this.curSpecInfo.spcPrice
            }
            this.infos.CNYallMoney = this.form.spcNum * this.curSpecInfo.spcPrice
        },
        //切换数量
        changeNum(e) {
            if (Object.keys(this.curSpecInfo).length > 0) {
                if (this.$i18n.locale == 'en') {
                    //澳元
                    this.infos.allMoney = e * this.curSpecInfo.spcAUDPrice
                } else {
                    this.infos.allMoney = e * this.curSpecInfo.spcPrice
                }
                this.infos.CNYallMoney = e * this.curSpecInfo.spcPrice
            } else {
                this.infos.allMoney = e * this.infos.allMoney
                this.infos.CNYallMoney = e * this.infos.CNYallMoney
            }
        },
        //关闭确认订单弹窗
        handleClose() {
            this.$emit('closeDialog', false)
        },
        //确认支付
        confirmSubmit() {
            if (!localStorage.getItem('USER_TOKEN')) {
                this.$message.error(this.$t('Tips7'))
                this.$emit('closeDialog', false)
                setTimeout(() => {
                    this.$store.commit('SHOWLOGIN')
                }, 1000);
                return
            }
            if (!this.form.linkName) {
                this.$message.error(this.$t('Order25'))
                return
            }
            if (!this.form.linkPhone) {
                this.$message.error(this.$t('Order26'))
                return
            }
            if (Object.keys(this.couponInfo).length > 0 &&
                Number(this.couponInfo.couponTopMoney) > (Number(this.realPrice) + Number(this.couponInfo.couponMoney))) {
                this.$message.error(this.$t('Order55'))
                return
            }
            let dataParams = {}
            dataParams = {
                orderSource: this.infos.orderSource,
                orderTypeCode: this.infos.orderTypeCode,
                name: this.form.linkName,//乘车人姓名
                phone: this.form.linkPhone,//乘车人电话
                refCouponId: this.couponInfo.id || '',//优惠券id
                couponMoney: this.couponInfo.couponMoney || 0,//优惠券金额
                payWay: this.form.curPay,//支付方式
                refDestinationId: this.infos.id || '',//商品id

                vipDiscount: this.CNYvipPrice,//会员价格
                sfMoney: this.CNYrealPrice,//实付价格
                orderPrice: this.infos.CNYallMoney,//总金额
            }
            Object.assign(dataParams, this.infos)
            if (this.curType == 1) {
                //目的地
                if (!this.form.spcNum) {
                    this.$message.error(this.$t('Order34'))
                    return
                }
                if (!this.form.specId) {
                    this.$message.error(this.$t('Order35'))
                    return
                }
                if (!this.form.serviceTime) {
                    this.$message.error(this.$t('Order36'))
                    return
                }
                let travelParams = {
                    spcNum: this.form.spcNum,//数量
                    refDestinationSpc: this.form.specId,//规格id
                    appointmentTime: this.form.serviceTime + ':00',//服务日期
                }
                Object.assign(dataParams, travelParams)
            } else if (this.curType == 2) {
                //旅游产品
                if (!this.form.spcNum) {
                    this.$message.error(this.$t('Order34'))
                    return
                }
                if (!this.form.specId) {
                    this.$message.error(this.$t('Order35'))
                    return
                }
                if (!this.form.serviceTime) {
                    this.$message.error(this.$t('Order36'))
                    return
                }
                let travelParams = {
                    beName: this.form.beName,
                    bePhone: this.form.bePhone,
                    beEmail: this.form.beEmail,
                    spcNum: this.form.spcNum,//数量
                    refDestinationSpc: this.form.specId,//规格id
                    appointmentTime: this.form.serviceTime + ':00',//服务日期
                }
                Object.assign(dataParams, travelParams)
            } else if (this.curType == 3) {
                //汽车租赁
                let carParams = {
                    rentNum: this.form.dayNum,
                }
                Object.assign(dataParams, carParams)
            }
            if (dataParams.specList) {
                delete dataParams.specList
            }
            $api.initOneTwoOrder(dataParams).then(res => {
                if (res.code == 200) {
                    let obj = {
                        orderId: res.data.id,
                        payWay: this.form.curPay.toString()
                    }
                    this.$emit('confirmPay', obj)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog-form {
    padding: 0 20px;

    .other-title {
        padding: 20px 0;
        font-weight: bold;
        font-size: 20px;
        color: #333;
    }

    .items {
        min-height: 58px;
        font-size: 20px;

        .labels {
            flex: 0 0 100px;
            color: #6f6f6f;
        }

        .values {
            color: #333;
        }
    }

    .inputs {
        margin-right: 20px;
        width: 240px;
        height: 50px;
        border: 1px solid #dadada;
        border-radius: 4px;
        box-sizing: border-box;
    }

    //优惠券
    .radio-item {
        margin: 5px 35px 5px 0;

        ::v-deep .el-radio__input {
            margin-top: 6px !important;
        }
    }

    .coupon-item {
        padding: 16px 0;
        width: 260px;
        height: 85px;
        background: #fff4f4;

        .lefts {
            flex: 0 0 70px;
            margin: 6px 0;
            padding: 0 5px;
            border-right: 2px dashed #d6d2d2;

            .price {
                font-size: 14px;
                color: #f00;

                span {
                    font-size: 20px;
                }
            }
        }

        .rights {
            width: 182px;
            padding: 0 5px 0 10px;
            overflow: hidden;

            .name {
                font-size: 16px;
                color: #333;
            }

            .condition {
                font-size: 14px;
                color: #999;
            }

            .time {
                font-size: 12px;
                color: #999;
            }
        }
    }
}
</style>