<!-- 悬浮按钮 -->
<template>
    <div class="float-box">
        <ul class="f-col-b-c">
            <li class="f-col-c-c relative">
                <img class="imgs" src="../../public/img/sy_zixun.png" alt="" />
                <span class="texts">{{ $t('My2') }}</span>
                <div class="code-box">
                    <div class="img-box f-col-c-c">
                        <img :src="platCode" alt="">
                        <p>{{ $t('My22') }}</p>
                    </div>
                    <div class="f-row-c-c">
                        <span class="foot-txt">{{ $t('My23') }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'float-btn',
    data() {
        return {
            platCode: '',//二维码
        }
    },
    created() {
        this.getPlatCode()
    },
    methods: {
        //获取平台客服二维码
        getPlatCode() {
            $api.platFormTextType({
                platFormType: 'USERSERVICE'
            }).then(res => {
                if (res.code == 200) {
                    this.platCode = res.data.context || ''
                } else {
                    this.$message.error(res.msg)
                }
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.float-box {
    position: fixed;
    top: 50%;
    right: 26px;
    z-index: 998;
    padding: 15px 0;
    width: 70px;
    height: 88px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);

    ul {
        height: 100%;
        cursor: pointer;
        user-select: none;

        li {
            position: relative;

            .imgs {
                width: auto;
                height: 30px;
            }

            .texts {
                margin-top: 12px;
                font-size: 14px;
                color: #121213;
                text-align: center;
            }

            .code-box {
                display: none;
                position: absolute;
                left: -245px;
                top: -30px;
                width: 222px;
                height: 238px;
                background: url('../../public/img/bg_zixun.png') no-repeat center center/cover;

                .img-box {
                    margin: 25px auto 10px;
                    width: 154px;
                    height: 164px;
                    background-color: #F6F6F6;
                    border-radius: 20px;

                    img {
                        width: 116px;
                        height: 116px;
                    }

                    p {
                        margin-top: 10px;
                        font-size: 12px;
                        color: #121213;
                        text-align: center;
                    }
                }

                .foot-txt {
                    font-size: 14px;
                    color: #121213;
                    text-align: center;
                }
            }

            &:hover {
                .texts {
                    color: #121213 !important;
                }

                .code-box {
                    display: block;
                }
            }
        }
    }
}
</style>