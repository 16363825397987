<template>
	<div class="head-box" :class="'head-' + showStyle">

		<div class="head-wrap f-row-b-c">
			<!-- 左边区 -->
			<div class="l-head">
				<div class="logo" @click="goHome">
					<img v-if="showStyle == 'white' || showStyle == 'null'" :src="require(`../../public/clxx_icon_logo.png`)"
						style="width:198px;cursor: pointer;" alt="">
					<img v-else :src="require(`../../public/index_icon_logo.png`)" style="width:198px;cursor: pointer;" alt="">
				</div>
			</div>
			<!-- 中间区 -->
			<div class="c-head" style="margin-left:190px;">
				<div class="nav-list f-row-e-c">
					<router-link to="/taxi">
						<div class="item" @click="navBar(1)">{{ $t('Nav1') }}</div>
					</router-link>
					<div class="line">|</div>
					<router-link to="">
						<div class="item" @click="goLink">{{ $t('Nav2') }}</div>
					</router-link>
					<div class="line">|</div>
					<router-link to="/travelList">
						<div class="item" @click="navBar(3)">{{ $t('Nav3') }}</div>
					</router-link>
					<div class="line">|</div>
					<router-link to="/download">
						<div class="item" @click="navBar(4)">{{ $t('Nav4') }}</div>
					</router-link>
				</div>
			</div>

			<!-- 右边区 -->
			<div class="r-head f-row-e-c">
				<div v-if="isLogin" class="nav-list f-row-e-c">
					<div v-if="userInfo.mobile" class="item">
						{{ userInfo.mobile.slice(0, 3) }}*****{{ userInfo.mobile.slice(-4) }}
					</div>
					<router-link to="/centre/order">
						<div class="item" @click="navBar(7)">{{ $t('Nav5') }}</div>
					</router-link>
					<div class="line">|</div>
					<div class="item" @click="handleBack">{{ $t('Nav6') }}</div>
				</div>

				<div v-else class="nav-list f-row-e-c">
					<div class="item" @click="$store.commit('SHOWLOGIN')">{{ $t('Nav7') }}</div>
					<div class="line">|</div>
					<div class="item" @click="$store.commit('SHOWREGISTER')">{{ $t('Nav8') }}</div>
				</div>
				<div class="languages">
					<el-select v-model="languageVal" @change="changeLanguage">
						<el-option v-for="item in languageList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>

			</div>
		</div>

		<!-- 登录弹窗 -->
		<el-dialog :fullscreen="true" :modal="false" :visible.sync="$store.state.showLogin">
			<div class="full-dialog f-row-e-c">
				<div class="full-close" @click="closeDialog(1)">
					<img src="../../public/img/close.png" alt="">
				</div>
				<div class="full-box f-col-c-c">
					<div class="full-contain">
						<div class="full-title">{{ $t('Nav7') }}</div>
						<div class="full-form">
							<div class="form-input">
								<div class="form-label">{{ $t('Login1') }}</div>
								<div class="form-val">
									<el-input v-model="login.phone" :placeholder="$t('Login2')"></el-input>
								</div>
							</div>
							<div class="form-input">
								<div class="form-label">{{ $t('Login3') }}</div>
								<div class="form-val">
									<el-input v-model="login.password" :placeholder="$t('Login4')" show-password></el-input>
								</div>
							</div>
						</div>
						<div class="full-btn" style="padding-top: 70px;">
							<button class="btn" :disabled="disabledLogin" @click="handleLogin">{{ $t('Nav7') }}</button>
						</div>
						<div class="fill-tips f-row-b-c">
							<div class="tit">{{ $t('Login5') }}<span @click="goRegister">{{ $t('Login6') }}</span></div>
							<div class="tits" @click="goForgetPass">{{ $t('Login7') }}</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>

		<!-- 注册弹窗 -->
		<el-dialog :fullscreen="true" :modal="false" :visible.sync="$store.state.showRegister">
			<div class="full-dialog f-row-e-c">
				<div class="full-close" @click="closeDialog(2)">
					<img src="../../public/img/close.png" alt="">
				</div>
				<div class="full-box f-col-c-c">
					<div class="full-contain">
						<div class="full-title">{{ $t('Nav8') }}</div>
						<div class="full-form">
							<div class="form-val f-row-s-c">
								<el-select class="common-select area-select" v-model="register.areaCode" :placeholder="$t('Tips10')">
									<el-option v-for="item in areaList" :key="item.id" :label="'+' + item.areaCode"
										:value="item.areaCode">
									</el-option>
								</el-select>
								<el-input v-model="register.phone" :placeholder="$t('Login2')"></el-input>
							</div>
							<div class="form-val f-row-b-c">
								<el-input v-model="register.code" :placeholder="$t('Login9')"></el-input>
								<div v-if="showCode" class="codes" @click="getCode(1)">{{ $t('Login10') }}</div>
								<div v-else class="codes">{{ times }}s{{ $t('Login11') }}</div>
							</div>
							<div class="form-val">
								<el-input v-model="register.password" show-password :placeholder="$t('Login12')"></el-input>
							</div>
							<div class="form-val">
								<el-input v-model="register.newpassword" show-password :placeholder="$t('Login13')"></el-input>
							</div>
							<div class="form-val">
								<el-input v-model="register.email" :placeholder="$t('Login14')"></el-input>
							</div>
						</div>
						<div class="full-txts">
							{{ $t('Login15') }}
							<span @click="goAgreement(1)">{{ $t('Login16') }}</span>
							{{ $t('Login17') }}
							<span @click="goAgreement(2)">{{ $t('Login18') }}</span>
						</div>
						<div class="full-btn">
							<button class="btn" :disabled="disabledRegister" @click="handleRegister">{{ $t('Nav8') }}</button>
						</div>
						<div class="fill-tips f-row-b-c">
							<div class="tit">{{ $t('Login20') }}<span @click="gologin">{{ $t('Login19') }}</span></div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>

		<!-- 重置密码弹窗 -->
		<el-dialog :fullscreen="true" :modal="false" :visible.sync="$store.state.showPass">
			<div class="full-dialog f-row-e-c">
				<div class="full-close" @click="closeDialog(3)">
					<img src="../../public/img/close.png" alt="">
				</div>
				<div class="full-box f-col-c-c">
					<div class="full-contain">
						<div class="full-title">{{ $t('Login21') }}</div>
						<div class="full-form">
							<div class="form-val f-row-s-c">
								<el-select class="common-select area-select" v-model="pass.areaCode" :placeholder="$t('Tips10')">
									<el-option v-for="item in areaList" :key="item.id" :label="'+' + item.areaCode"
										:value="item.areaCode">
									</el-option>
								</el-select>
								<el-input v-model="pass.phone" :placeholder="$t('Login2')"></el-input>
							</div>
							<div class="form-val f-row-b-c">
								<el-input v-model="pass.code" :placeholder="$t('Login9')"></el-input>
								<div v-if="showCode" class="codes" @click="getCode(2)">{{ $t('Login10') }}</div>
								<div v-else class="codes">{{ times }}s{{ $t('Login11') }}</div>
							</div>
							<div class="form-val">
								<el-input v-model="pass.password" show-password :placeholder="$t('Login12')"></el-input>
							</div>
							<div class="form-val">
								<el-input v-model="pass.newpassword" show-password :placeholder="$t('Login13')"></el-input>
							</div>
						</div>
						<div class="full-btn">
							<button class="btn" :disabled="disabledPass" @click="handlePass">{{ $t('Login21') }}</button>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
import $api from '@/api/api.js'
export default {
	props: {
		//显示样式
		showStyle: {
			type: String,
			default: 'black',//black white
		},
	},
	data() {
		return {
			languageVal: 'zh',
			languageList: [{
				value: "zh",
				label: "中文",
			}, {
				value: "en",
				label: "English",
			}],

			isLogin: false,//是否已登录
			navActive: null,//当前导航样式
			areaList: [],//手机区号列表
			showCode: true, //发送验证码状态
			times: 60,//倒计时 秒

			//账号信息
			userInfo: {
				mobile: ''
			},
			//登录信息
			login: {
				phone: "",
				password: ""
			},
			//注册信息
			register: {
				phone: "",
				areaCode: "",
				code: "",
				password: "",
				newpassword: "",
				email: ""
			},
			//重置密码信息
			pass: {
				phone: "",
				areaCode: "",
				code: "",
				password: "",
				newpassword: "",
			},
		}
	},
	computed: {
		disabledLogin() {
			for (let key in this.login) {
				if (this.login[key] === '') {
					return true;
				}
			}
			return false;
		},
		disabledRegister() {
			for (let key in this.register) {
				if (this.register[key] === '') {
					return true;
				}
			}
			return false;
		},
		disabledPass() {
			for (let key in this.pass) {
				if (this.pass[key] === '') {
					return true;
				}
			}
			return false;
		},
	},
	created() {
		this.getPhoneAreaList()

		if (localStorage.getItem('language')) {
			this.$i18n.locale = localStorage.getItem('language');
			this.languageVal = localStorage.getItem('language');
		}

		if (localStorage.getItem('USER_TOKEN')) {
			this.getUserInfo()
			this.isLogin = true
		} else {
			localStorage.clear()
		}
	},
	mounted() {
		this.navActive = sessionStorage.getItem('ACTIVE_MENU');
	},
	methods: {
		//切换语言
		changeLanguage(e) {
			this.$nextTick(() => {
				this.$i18n.locale = e;
				localStorage.setItem('language', e)
				location.reload();
			});
		},
		//获取当前账号信息
		getUserInfo() {
			$api.getHighImportInfo().then(res => {
				if (res.code == 200) {
					this.userInfo = res.data || {}
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//获取手机区号列表
		getPhoneAreaList() {
			$api.getPhoneAreaList().then(res => {
				if (res.code == 200) {
					this.areaList = res.data || {}
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//设置当前导航样式
		navBar(id) {
			this.navActive = id;
			sessionStorage.setItem('ACTIVE_MENU', id);
		},
		//logo跳转
		goHome() {
			window.location.href = "/"
		},
		//去汽车租赁
		goLink() {
			$api.platFormTextType({
				platFormType: 'COLLABORATIONLINK'
			}).then(res => {
				if (res.code == 200) {
					let url = res.data.context || ''
					window.open(url)
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//去用户协议
		goAgreement(type) {
			this.$store.commit('NOSHOWREGISTER')
			this.$router.push({ path: '/agreement', query: { type } })
		},
		//退出登录
		handleBack() {
			this.$confirm(this.$t('Tips2'), this.$t('Tips1'), {
				confirmButtonText: this.$t('Tips3'),
				cancelButtonText: this.$t('Tips4'),
				type: 'warning'
			}).then(() => {
				localStorage.clear()
				// localStorage.removeItem('USER_TOKEN')
				this.isLogin = false
				this.$message.success(this.$t('Tips5'))
				window.location.href = "/"
			}).catch(() => { })
		},
		//获取验证码 1注册 2重置密码
		getCode(type) {
			let testVal = ''
			let areaVal = ''
			if (type == 1) {
				testVal = this.register.phone
				areaVal = this.register.areaCode
			} else if (type == 2) {
				testVal = this.pass.phone
				areaVal = this.pass.areaCode
			}
			if (!testVal) {
				this.$message.error(this.$t('Login22'))
				return
			}
			if (!areaVal) {
				this.$message.error(this.$t('Login8'))
				return
			}
			//if (/^1[3-9]{1}[0-9]{9}$/.test(testVal)) {
			$api[type == 1 ? 'sendAccountCode' : 'passwordBlackCode']({
				mobile: testVal,
				areaCode: areaVal
			}).then(res => {
				if (res.code == 200) {
					this.$message.success(this.$t('Loadtxt1'))
					this.showCode = false
					let timer = setInterval(() => {
						if (this.times > 0) {
							this.times--
						} else {
							this.showCode = true
							this.times = 60
							clearInterval(timer)
						}
					}, 1000)
				} else {
					this.$message.error(res.msg)
				}
			})
			// } else {
			// 	this.$message.error(this.$t('Login22'))
			// }
		},
		//登录
		handleLogin() {
			let ids = ''
			if (localStorage.getItem('supId')) {
				ids = localStorage.getItem('supId')
			}
			$api.appLogin({
				mobile: this.login.phone,
				password: this.login.password,
				supId: ids
			}).then(res => {
				if (res.code == 200) {
					localStorage.setItem('USER_TOKEN', res.data)
					this.isLogin = true
					this.$store.commit('NOSHOWLOGIN')
					this.$message.success(this.$t('Loadtxt2'))
					this.login = {
						phone: "",
						password: ""
					}
					this.getUserInfo()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//注册
		handleRegister() {
			$api.appRegister({
				mobile: this.register.phone,
				yzParm: this.register.code,
				password: this.register.password,
				surePassword: this.register.newpassword,
				email: this.register.email
			}).then(res => {
				if (res.code == 200) {
					this.$store.commit('NOSHOWREGISTER')
					this.register = {
						phone: "",
						code: "",
						password: "",
						newpassword: "",
						email: ""
					}
					this.$message.success(this.$t('Loadtxt3'))
					setTimeout(() => {
						this.$store.commit('SHOWLOGIN')
					}, 500);
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//重置密码
		handlePass() {
			$api.forgotPassword({
				password: this.pass.password,
				yzParm: this.pass.code,
				surePassword: this.pass.newpassword,
				mobile: this.pass.phone
			}).then(res => {
				if (res.code == 200) {
					this.$store.commit('NOSHOWPASS')
					this.pass = {
						phone: "",
						code: "",
						password: "",
						newpassword: "",
					}
					this.$message.success(this.$t('Loadtxt4'))
					setTimeout(() => {
						this.$store.commit('SHOWLOGIN')
					}, 500);
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//关闭弹窗
		closeDialog(type) {
			if (type == 1) {
				this.$store.commit('NOSHOWLOGIN')
			} else if (type == 2) {
				this.$store.commit('NOSHOWREGISTER')
			} else if (type == 3) {
				this.$store.commit('NOSHOWPASS')
			}
		},
		//跳转到注册
		goRegister() {
			this.$store.commit('NOSHOWLOGIN')
			this.$store.commit('SHOWREGISTER')
		},
		//跳转到忘记密码
		goForgetPass() {
			this.$store.commit('NOSHOWLOGIN')
			this.$store.commit('SHOWPASS')
		},
		//跳转到登录
		gologin() {
			this.$store.commit('NOSHOWREGISTER')
			this.$store.commit('SHOWLOGIN')
		},
	}
}
</script>

<style lang="scss" scoped>
@media screen and (max-width:1560px) {
	.head-wrap {
		width: 1200px !important;
	}
}

.head-box {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 96px;
	background: #0000004d;

	.head-wrap {
		margin: auto;
		width: 1560px;
		height: 100%;
	}
}

.nav-list {
	gap: 22px;
	color: #fff;

	.item {
		font-size: 14px;
		color: #fff;
		cursor: pointer;
		user-select: none;

		&:hover {
			font-weight: bold;
		}
	}
}

.languages {
	width: 100px;
	height: 40px;

	::v-deep .el-select {
		margin-top: -2px;
		height: 100%;
	}

	::v-deep .el-input__inner {
		font-size: 14px !important;
		color: #fff !important;
		background: transparent !important;
		border: none !important;
	}

	::v-deep .el-select .el-input .el-select__caret {
		font-size: 14px !important;
		color: #fff !important;
	}
}

//其他样式
.head-white {
	background: #fff;
	box-shadow: 0 0 3px 3px #eee;

	.nav-list {
		color: #333;

		.item {
			color: #333;
		}
	}

	.languages {
		width: 100px;
		height: 40px;

		::v-deep .el-input__inner {
			color: #333 !important;
		}
	}
}

.head-null {
	background: transparent;
}

//全屏弹窗
.full-dialog {
	position: relative;
	height: 100vh;
	background: url('../../public/img/login_bg.png') no-repeat center center/cover;
	background-attachment: fixed;

	.full-close {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
	}

	.full-box {
		width: 50%;
		height: 100%;
		background: #fff;

		.full-contain {
			width: 634px;
		}
	}

	.full-title {
		margin-bottom: 46px;
		font-weight: bold;
		font-size: 42px;
		color: #333;
	}

	.form-label {
		padding: 22px 0 28px;
		font-weight: bold;
		font-size: 22px;
		color: #333;
	}

	.form-val {
		position: relative;
		margin-bottom: 16px;
		height: 76px;
		border: 1px solid #dadada;
		border-radius: 4px;

		.codes {
			flex: 0 0 140px;
			font-size: 22px;
			color: var(--theme-color);
			cursor: pointer;
		}

		.area-select {
			flex: 0 0 200px;
			height: 100%;
			border: none;
		}
	}

	.full-txts {
		font-size: 16px;
		color: #999;

		span {
			color: var(--theme-color);
			cursor: pointer;
		}
	}

	.full-btn {
		padding: 30px 0;

		.btn {
			padding: 0;
			width: 100%;
			height: 94px;
			line-height: 94px;
		}
	}

	.fill-tips {
		font-size: 22px;
		color: #333;

		span {
			color: var(--theme-color);
			cursor: pointer;
		}

		.tits {
			cursor: pointer;
		}
	}
}
</style>