<template>
    <div>
        <v-header showStyle="white"></v-header>
        <div class="bg-box">
            <div class="wrap">
                <div class="info-box contain-box f-row-b-c">
                    <div class="l-info">
                        <div class="names">{{ info.desName || '' }}</div>
                        <div v-if="info.dmDestinationSpcList && info.dmDestinationSpcList.length > 0" class="prices">
                            <p v-if="curType == 3">￥ <span>{{ info.desMoney || '0.00' }}</span>/天</p>
                            <p v-else>
                                <template v-if="$i18n.locale == 'en'">
                                    $<span style="font-size:20px">
                                        {{ info.dmDestinationSpcList[0].spcAUDPrice || '0' }}
                                    </span>
                                </template>
                                <template v-else>
                                    ￥<span style="font-size:20px">{{ info.dmDestinationSpcList[0].spcPrice || '0' }}</span>
                                </template>
                            </p>
                        </div>
                    </div>
                    <div v-if="info.dmDestinationSpcList && info.dmDestinationSpcList.length > 0" class="r-info f-row-c-c" @click="goBuy">
                        立即{{ curType == 3 ? '租赁' : '购买' }}
                    </div>
                </div>

                <div class="content-box">
                    <div class="text-box" v-html="info.infoBannerImg"></div>
                    <div class="img-box" v-for="item in getImgList(info.desContext)" :key="item">
                        <img :src="item" alt="" height="auto">
                    </div>
                </div>

            </div>

            <!-- 确认订单弹窗 -->
            <vorder-dialog ref="orderChild" :curType="curType" :isShow="orderDialog" :infos="orderInfo"
                @closeDialog="closeOrderDialog" @confirmPay="confirmPay"></vorder-dialog>

            <!-- 支付二维码弹窗 -->
            <vpay-dialog ref="payChild" :isShow="payDialog" :infos="codeInfo" :payWay="currentPay"
                @closeDialog="closePayDialog"></vpay-dialog>

        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    data() {
        return {
            curType: '1',//1目的地 2旅游产品 3汽车租赁
            info: {},//详情数据

            //显示弹窗--确认订单
            orderDialog: false,
            orderInfo: {},//确认订单信息

            //显示弹窗--扫码支付
            payDialog: false,
            currentPay: '1',//支付方式
            codeInfo: {
                id: '',
                urlCode: '',
            },
        }
    },
    created() {
        this.curType = this.$route.query.type
        this.getData()
    },
    methods: {
        //获取详情数据
        getData() {
            $api.selectDmDestinationInfo({
                destinationId: this.$route.query.id
            }).then(res => {
                if (res.code == 200) {
                    this.info = res.data || {}
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //设置显示图片
        getImgList(list) {
            return list ? list.split(',') : ''
        },
        //立即购买--打开确认订单
        goBuy() {
            let alls = 0
            if (this.$i18n.locale == 'en') {
                alls = this.info.dmDestinationSpcList[0].spcAUDPrice
            } else {
                alls = this.info.dmDestinationSpcList[0].spcPrice
            }
            this.orderInfo = {}
            this.orderInfo = {
                orderSource: 'PC',
                id: this.info.id,
                desName: this.info.desName,
                allMoney: alls,
                CNYallMoney: this.info.dmDestinationSpcList[0].spcPrice || 0,
            }
            if (this.curType == 1) {//目的地
                let params1 = {
                    orderTypeCode: 'POPULAR_DESTINATION_SERVICES',
                    specList: this.info.dmDestinationSpcList,//规格列表
                }
                Object.assign(this.orderInfo, params1)
            } else if (this.curType == 2) {//旅游产品
                let params2 = {
                    orderTypeCode: 'TOURISM_PRODUCTS',
                    specList: this.info.dmDestinationSpcList,//规格列表
                }
                Object.assign(this.orderInfo, params2)
            } else if (this.curType == 3) {//汽车租赁
                let params3 = {
                    orderTypeCode: 'CAR_RENTAL',
                }
                Object.assign(this.orderInfo, params3)
            }
            this.orderDialog = true
        },
        //关闭弹窗--确认订单
        closeOrderDialog() {
            this.orderDialog = false
        },
        //关闭弹窗--扫码支付
        closePayDialog() {
            this.payDialog = false
        },
        //确认弹窗--打开扫码支付弹窗
        confirmPay(backObj) {
            this.currentPay = backObj.payWay
            let params = {
                orderId: backObj.orderId,
                orderType: 2, //1企业 2用户
            }
            //获取支付信息
            $api[backObj.payWay == 1 ? 'setzfbPay' : 'setWxPay'](params).then(res => {
                if (res.code == 200) {
                    this.codeInfo = res.data || {}
                    this.$refs.payChild.getOpenDialog()
                    this.payDialog = true
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.info-box {
    margin-bottom: 20px;
    padding: 30px;
}

.l-info {
    .names {
        margin-bottom: 20px;
        line-height: 36px;
        font-weight: bold;
        font-size: 26px;
        color: #333;
    }

    .prices {
        font-weight: bold;
        font-size: 14px;
        color: #f9672e;
    }
}

.r-info {
    flex: 0 0 272px;
    width: 272px;
    height: 60px;
    font-size: 22px;
    color: #fff;
    background: var(--theme-color);
    border-radius: 4px;
    cursor: pointer;
}

.content-box {
    padding: 10px;
    line-height: 26px;
    background: #fff;
    border-radius: 4px;
    word-wrap: break-word;

    .text-box {
        margin-bottom: 10px;
        font-size: 16px;
        color: #333;
    }

    .img-box {
        margin-bottom: 20px;
        border-radius: 4px;
        overflow: hidden;
    }
}

//支付弹窗
.code-img {
    text-align: center;

    .counts {
        padding: 15px 0;
        font-size: 18px;
        color: #333;
    }

    .imgs {
        margin: auto;
        width: 240px;
        height: 240px;
    }

    .tips {
        margin: 10px 0;
        font-size: 16px;
        color: #333;
    }
}
</style>