<template>
    <div class="loading-box">
        <div class="form-tabs f-row-s-e">
            <div class="tabs f-row-c-c" :class="{ 'active': curTabs == index }" v-for="(item, index) in formTabs"
                :key="index" @click="changeTabs(index)">
                {{ item }}
            </div>
        </div>
        <div class="form-con">
            <div class="f-row-s-c f-w" style="column-gap:30px;">
                <div class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../public/img/order1.png" alt="">{{ $t('Order3') }}
                    </div>
                    <div class="address-box">
                        <v-address width="328px" height="50px" @inputValue="inputValueStart"
                            :placeholder="$t('Order4')"></v-address>
                    </div>
                </div>
                <div v-if="curTabs == 0" class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../public/img/order1.png" alt="">{{ $t('Order5') }}
                    </div>
                    <div class="address-box">
                        <v-address width="328px" height="50px" @inputValue="inputValueEnd"
                        :placeholder="$t('Order6')"></v-address>
                    </div>
                </div>
                <div class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../public/img/index_icon_renshu.png" alt="">{{ $t('Order7') }}
                    </div>
                    <div class="inputs">
                        <el-input v-model="form.carPerson" :placeholder="$t('Order8')" clearable></el-input>
                    </div>
                </div>
                <div v-if="curTabs == 1" class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../public/img/index_icon_time.png" alt="">{{ $t('Order9') }}
                    </div>
                    <div class="car-box">
                        <el-select class="common-select" v-model="form.carTimeId" :placeholder="$t('Order10')"
                            @change="changeCarTime">
                            <el-option v-for="item in carTimeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../public/img/order2.png" alt="">{{ $t('Order11') }}
                    </div>
                    <div class="time-box inputs f-row-b-c">
                        <el-date-picker type="date" v-model="form.date" prefix-icon="0" :placeholder="$t('Order12')"
                            value-format="yyyy-MM-dd" clearable>
                        </el-date-picker>
                        <i class="el-icon-arrow-down"></i>
                    </div>
                </div>
                <div class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../public/img/index_icon_time.png" alt="">{{ $t('Order13') }}
                    </div>
                    <div class="time-box inputs f-row-b-c">
                        <el-time-picker v-model="form.time" prefix-icon="0" :placeholder="$t('Order14')" value-format="HH:mm"
                            format="HH:mm" clearable>
                        </el-time-picker>
                        <i class="el-icon-arrow-down"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="operate-box f-row-c-c">
            <button class="btn" @click="goCalculate">{{ $t('Order15') }}</button>
        </div>

    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: "VhomeForm",
    data() {
        return {
            curTabs: 0,
            formTabs: [this.$t('Order1'), this.$t('Order2')],
            form: {
                startAddress: '',//出发地
                endAddress: '',//到达地
                carPerson: '',//乘客数
                date: '', //出发日期
                time: '', //出发时间
                carTimeId: '',//用车时长id
            },

            carTimeList: [],//用车时长列表
            carTimeInfo: {},//选中的用车时长
        }
    },
    created() {
        this.getCarTimeList()
    },
    methods: {
        //获取用车时长列表
        getCarTimeList() {
            $api.listTime().then(res => {
                if (res.code == 200) {
                    let querys = res.data || []
                    this.carTimeList = []
                    if (querys && querys.length > 0) {
                        querys.forEach(i => {
                            this.carTimeList.push({
                                label: i.rentalDuration + 'H',
                                value: i.rentalDuration,
                            })
                        })
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //切换formtabs
        changeTabs(i) {
            this.curTabs = i
            if (i == 0) {
                this.orderDialogType = '4'
            } else if (i == 1) {
                this.orderDialogType = '6'
            }
        },
        //赋值出发地地址
        inputValueStart(e) {
            this.form.startAddress = e;
        },
        //赋值结束地地址
        inputValueEnd(e) {
            this.form.endAddress = e;
        },
        //选择用车时长
        changeCarTime(e) {
            this.carTimeInfo = this.carTimeList.find((item) => item.value == e)
        },
        //查询
        goCalculate() {
            if (!this.form.startAddress) {
                this.$message.error(this.$t('Order4'))
                return
            }
            if (!this.form.carPerson) {
                this.$message.error(this.$t('Order8'))
                return
            }
            if (!this.form.date) {
                this.$message.error(this.$t('Order12'))
                return
            }
            if (!this.form.time) {
                this.$message.error(this.$t('Order14'))
                return
            }
            let dataParams = {}
            let params = {}
            dataParams = {
                setOutAddress: this.form.startAddress,
                numberOfPassengers: this.form.carPerson,
                appointmentTime: this.form.date + ' ' + this.form.time + ':00',
            }
            if (this.curTabs == 0) {
                //专车服务
                if (!this.form.endAddress) {
                    this.$message.error(this.$t('Order6'))
                    return
                }
                const loading = this.$loading('loading-box', this.$t('Load1'), true, 'el-icon-loading', 'rgba(0, 0, 0, 0.8)')
                //计算距离
                $api.distanceCalculation({
                    originsAddress: this.form.startAddress,
                    destinationsAddress: this.form.endAddress,
                }).then(res => {
                    loading.close()
                    if (res.code == 200) {
                        this.$message.success(this.$t('Loadtxt5'))
                        let datas = res.data || {}
                        params = {
                            orderTypeCode: 'SPECIAL_CAR_SERVICE',
                            arriveOutAddress: this.form.endAddress,
                            travelDistance: datas.kilometers || '0',
                            toll: datas.toll || '0',
                        }
                        Object.assign(dataParams, params)
                        localStorage.setItem('orderInfo', encodeURIComponent(JSON.stringify(dataParams)))
                        this.$router.push({
                            path: '/carList', query: { type: '4' }
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else if (this.curTabs == 1) {
                //包车服务
                if (Object.keys(this.carTimeInfo).length == 0) {
                    this.$message.error(this.$t('Order10'))
                    return
                }
                params = {
                    orderTypeCode: 'SINGLE_CHARTER',
                    vehicleDuration: this.form.carTimeId,
                }
                Object.assign(dataParams, params)
                localStorage.setItem('orderInfo', encodeURIComponent(JSON.stringify(dataParams)))
                this.$router.push({
                    path: '/carList', query: { type: '6' }
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.inputs {
    padding: 0 14px;
    width: 328px;
    height: 50px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-tabs {
    position: absolute;
    left: 0;
    top: -75px;
    z-index: 1;

    .tabs {
        padding: 0 6px;
        min-width: 256px;
        height: 68px;
        font-weight: bold;
        font-size: 28px;
        color: #333;
        background: #f1f1f1;
        border-radius: 5px 5px 0 0;
        cursor: pointer;

        &.active {
            height: 75px;
            color: #fff;
            background: var(--theme-color);
        }
    }
}

.card-tit {
    padding: 30px 0 12px;
    font-size: 18px;
    color: #333;

    img {
        margin-right: 8px;
        max-width: 18px;
        width: auto;
    }
}

.car-box {
    position: relative;

    .common-select {
        width: 328px;
        height: 50px;
    }

    .car-tips {
        position: absolute;
        padding: 0 20px;
        line-height: 28px;
        font-size: 14px;
        color: #FE4E00;
        background: #fffaf7;
        border-radius: 4px;
    }
}

.operate-box {
    padding: 20px 0;

    .btn {
        width: 376px;
        height: 56px;
        line-height: 56px;
        font-size: 20px;
    }
}

.price-box {
    width: 500px;
    height: 56px;
    background: #f3f3f3;
    border-radius: 4px;
    overflow: hidden;

    .l-price {
        padding-left: 18px;
        font-size: 18px;
        color: #333;

        .nums {
            font-size: 14px;
            color: #fa692a;

            span {
                font-weight: bold;
                font-size: 20px;
            }
        }
    }

    .r-price {
        width: 220px;
        height: 100%;
        font-size: 20px;
        color: #fff;
        background: var(--theme-color);
        cursor: pointer;
    }
}
</style>