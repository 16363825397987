<template>
    <div>
        <el-dialog :visible.sync="isShow" width="500px" :show-close="false" @close="closeDialog">
            <!-- 头部区 -->
            <div class="dialog-top f-row-b-c" slot="title">
                <span class="titles">{{$t('Order58')}}</span>
                <i class="closes el-icon-close" @click="closeDialog"></i>
            </div>
            <!-- 内容区 -->
            <div class="code-img">
                <div class="counts">
                    <span>{{$t('Order59')}}：<span style="color:red">{{ min }}:{{ sec }}</span></span>
                </div>
                <img class="imgs" :src="infos.urlCode">
                <div class="tips" v-if="infos.urlCode">{{ payWay == 1 ? $t('Order60') : $t('Order61') }}</div>
            </div>
            <!-- 底部区 -->
            <div class="dialog-foot f-row-c-c" slot="footer">
                <el-button type="primary" @click="closeDialog">{{$t('Btn1')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'Vpay-dialog',
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        payWay: {
            type: String,
            default: '1'
        },
        infos: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            intervalId: '',
            //倒计时
            min: '',
            sec: '',
            end: '',
        }
    },
    destroyed() {
        clearInterval(this.intervalId)
    },
    methods: {
        //关闭弹窗
        closeDialog(){
            clearInterval(this.intervalId)
            this.$emit('closeDialog', false)
        },
        //设置定时器
        getOpenDialog() {
            this.intervalId = setInterval(() => {
                this.hasPay(this.infos.id)
            }, 3000)
            //定义五分钟结束
            this.end = Date.parse(new Date()) + 300000
            this.countdown()
        },
        //判断是否付款
        hasPay(id) {
            $api.setCodePayEnd({
                id: id
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(this.$t('Loadtxt6'))
                    clearInterval(this.intervalId)
                    this.$emit('closeDialog', true)
                }
                if (res.msg == '支付成功') {
                    setTimeout(() => {
                        this.$router.push({ path: '/centre/order', query: {} })
                    }, 1000)
                }
            })
        },
        //倒计时5分钟
        countdown() {
            let end = this.end
            const now = Date.parse(new Date())
            const msec = end - now
            if (msec < 0) return;
            let min = parseInt(msec / 1000 / 60 % 60)
            let sec = parseInt(msec / 1000 % 60)
            this.min = min > 9 ? min : '0' + min
            this.sec = sec > 9 ? sec : '0' + sec
            const that = this
            if (min >= 0 && sec >= 0) {
                //倒计时结束关闭订单
                if (min == 0 && sec == 0) {
                    this.$message.error(this.$t('Order62'))
                    clearInterval(this.intervalId)
                    this.$emit('closeDialog', false)
                    return
                }
                setTimeout(function () {
                    that.countdown()
                }, 1000)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.code-img {
    text-align: center;

    .counts {
        padding: 15px 0;
        font-size: 18px;
        color: #333;
    }

    .imgs {
        margin: auto;
        width: 240px;
        height: 240px;
    }

    .tips {
        margin: 10px 0;
        font-size: 16px;
        color: #333;
    }
}
</style>