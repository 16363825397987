<template>
    <div>
        <v-header showStyle="white"></v-header>
        <div class="bg-box">
            <div class="wrap contain-box">
                <div class="title">{{ title }}</div>
                <div class="info" v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'agreement',
    data() {
        return {
            type: '1',//协议类型 1用户协议 2隐私政策
            title: this.$t('Home5'),
            content: '',
        }
    },
    created() {
        this.type = this.$route.query.type
        this.getData(this.type)
    },
    methods: {
        //获取协议信息
        getData(types) {
            let paramsVal = ''
            if (types == 1) {
                this.title = this.$t('Home5')
                paramsVal = 'USERAGREEMENT'
            } else if (types == 2) {
                this.title = this.$t('Home4')
                paramsVal = 'PRIVACYPOLICY'
            }
            $api.platFormTextType({
                platFormType: paramsVal
            }).then(res => {
                this.content = res.data.context || ''
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.contain-box {
    padding: 30px;
    min-height: 100vh;

    .title {
        line-height: 44px;
        font-weight: bold;
        font-size: 26px;
        color: #333;
        text-align: center;
    }

    .info {
        line-height: 30px;
        font-size: 18px;
        color: #333;
    }
}
</style>