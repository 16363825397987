<template>
    <div>
        <v-header showStyle="white"></v-header>
        <div class="bg-box">
            <div class="wrap contain-box">

                <div class="main-title f-row-s-c">
                    <div class="line"></div>{{ $t('Custom1') }}
                </div>

                <div class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../../public/img/order1.png" alt="">{{ $t('Custom2') }}
                    </div>
                    <div class="address-box">
                        <v-address width="630px" @inputValue="inputValueEnd" :placeholder="$t('Custom3')"></v-address>
                    </div>
                </div>

                <div class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../../public/img/order1.png" alt="">{{ $t('Custom4') }}
                    </div>
                    <div class="common-inputs">
                        <el-input v-model="form.budget" :placeholder="$t('Custom5')" clearable></el-input>
                    </div>
                </div>

                <div class="card-box">
                    <div class="card-tit f-row-s-c">
                        <img src="../../../public/img/order1.png" alt="">{{ $t('Custom6') }}
                    </div>
                    <div class="f-row-s-c">
                        <div class="common-inputs" style="margin-right:22px;width:306px;">
                            <el-input v-model="form.contactsName" :placeholder="$t('Order25')" clearable></el-input>
                        </div>
                        <div class="common-inputs" style="width:306px;">
                            <el-input v-model="form.contactsMobile" :placeholder="$t('Order26')" clearable></el-input>
                        </div>
                    </div>
                </div>

                <div class="common-btn f-row-s-c">
                    <button class="btn" :disabled="desabledBtn" @click="handleSubmit">{{ $t('Btn4') }}</button>
                </div>

                <!-- 提交历史 -->
                <div class="loading-box">
                    <template v-if="historyList && historyList.length > 0">
                        <div class="list-box f-w">
                            <div class="list-item" v-for="(item, index) in historyList" :key="index">
                                <div class="top f-row-b-c">
                                    <div class="time">{{ item.createTime }}</div>
                                    <div class="del" @click="handleDel(item.id)">
                                        <i class="el-icon-delete"></i>
                                    </div>
                                </div>
                                <div class="info">{{ $t('Custom2') }}：{{ item.destinationCity }}</div>
                                <div class="info">{{ $t('Custom4') }}：{{ item.budget }} </div>
                                <div class="info">{{ $t('Custom6') }}：{{ item.contactsName }} {{ item.contactsMobile }}</div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="no-content f-row-c-c" style="height:140px;">{{ $t('Custom7') }}</div>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'customization',
    data() {
        return {
            form: {
                destinationCity: '',
                budget: '',
                contactsName: '',
                contactsMobile: '',
            },
            historyList: [],//提交历史列表
        }
    },
    computed: {
        desabledBtn() {
            for (let key in this.form) {
                if (this.form[key] === '') {
                    return true;
                }
            }
            return false;
        }
    },
    mounted() {
        this.getHistoryList()
    },
    methods: {
        //赋值目的地地址
        inputValueEnd(e) {
            this.form.destinationCity = e;
        },
        //获取提交历史列表
        getHistoryList() {
            const loading = this.$loading('.loading-box', this.$t('Load2'), true)
            $api.selectDmCustomizedList().then(res => {
                loading.close()
                if (res.code == 200) {
                    this.historyList = res.rows || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //删除历史
        handleDel(id) {
            this.$confirm(this.$t('Tips11'), this.$t('Tips1'), {
                confirmButtonText: this.$t('Tips3'),
                cancelButtonText: this.$t('Tips4'),
                type: 'warning'
            }).then(() => {
                $api.deleteCustomized(id).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.getList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => { })
        },
        //提交
        handleSubmit() {
            $api.insertDmCustomized({
                destinationCity: this.form.destinationCity,
                budget: this.form.budget,
                contactsName: this.form.contactsName,
                contactsMobile: this.form.contactsMobile
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.getHistoryList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.common-inputs {
    width: 632px;
}

.common-btn {
    margin: 30px 0;
}

.contain-box {
    padding: 30px 46px;
}

.card-tit {
    padding: 30px 0 20px;
    font-size: 18px;
    color: #333;

    img {
        margin-right: 8px;
        max-width: 18px;
        width: auto;
    }
}

.list-box {
    margin-top: 30px;
    gap: 20px 30px;

    .list-item {
        padding: 18px 20px;
        width: calc(100% / 3 - 20px);
        border: 1px solid #ddd;
        border-radius: 4px;

        .top {
            .time {
                line-height: 25px;
                font-size: 14px;
                color: #8A8A8A;
            }

            i {
                font-size: 18px;
                cursor: pointer;
            }
        }

        .info {
            line-height: 25px;
            font-size: 14px;
            color: #333;
        }
    }
}
</style>