<template>
    <div class="boxs flex">
        <div class="l-car">
            <img :src="item.coverImage" alt="">
        </div>
        <div class="r-car f-row-b-c flex1">
            <div class="lefts f-col-b-s">
                <div class="names one-txt-cut">{{ item.carTypeName }}</div>
                <div class="infos two-txt-cut">{{ item.contentValidity }}</div>
                <div class="serves f-row-s-c">
                    <div v-if="item.passengerCapacity" class="ins f-row-s-c">
                        <img src="../../public/img/clxx_icon_rs.png" alt="">
                        <div>{{ item.passengerCapacity }}</div>
                    </div>
                    <div v-if="item.luggageBearCapacity" class="ins f-row-s-c">
                        <img src="../../public/img/clxx_icon_xl.png" alt="">
                        <div>{{ item.luggageBearCapacity || 0 }}{{$t('Order64')}}</div>
                    </div>
                    <div v-if="item.customerServiceOrNot == 1" class="ins f-row-s-c">
                        <img src="../../public/img/clxx_icon_kf.png" alt="">
                        <div>{{ $t('Order63') }}</div>
                    </div>
                </div>
                <p v-if="types == 6 && item.busPrompt" class="tips ell2">包车提示：{{ item.busPrompt || ''}}</p>
            </div>
            <div class="prices f-col-a-e">
                <div class="num1">
                    <template v-if="$i18n.locale == 'en'">
                        $<span style="font-size: 24px;">{{ item.audMoney || '0.00' }}</span>
                    </template>
                    <template v-else>
                        ¥<span style="font-size: 24px;">{{ item.unitPrice || '0.00' }}</span>
                    </template>
                </div>
                <div v-if="showNum" class="num2">×<span>{{ item.num }}</span></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VCar",
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        //是否显示数量
        showNum:{
            type: Boolean,
            default: true
        },
        //显示页
        showPage:{
            type: String,
            default: ''
        },
        //类型
        types:{
            type: String,
            default: '4'
        }
    },
}
</script>

<style lang="scss" scoped>
.l-car {
    margin-right: 20px;
    flex: 0 0 152px;

    img {
        width: 152px;
        height: 118px;
        border-radius: 10px;
        object-fit: cover;
    }
}

.r-car {
    .lefts{
        max-width: 670px;
        min-height: 90px;
    }
    .names {
        margin-bottom: 10px;
        font-size: 18px;
        color: #000000;
    }

    .infos {
        margin-bottom: 20px;
        font-size: 16px;
        color: #808080;
    }

    .serves {
        font-size: 16px;
        color: #000;

        .ins {
            margin-right: 40px;
        }

        img {
            margin-right: 6px;
            width: auto;
            height: 16px;
        }
    }

    .tips{
        margin-top: 10px;
        font-size: 14px;
        color: #f00;
    }

    .prices {
        min-height: 90px;
        font-size: 22px;
        color: #2E2D2D;

        .num1 {
            font-weight: bold;
            font-size: 18px;
            color: var(--price-color);
        }

        .num2 {
            font-size: 16px;
            color: #2E2D2D;

            span {
                font-size: 22px;
            }
        }
    }
}</style>