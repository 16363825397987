<template>
    <div>
        <!-- 头部区 -->
        <div class="top-box f-row-s-c">
            <div class="time">{{ $t('MOrder1') }}：{{ item.createTime || '' }}</div>
            <div class="code">{{ $t('MOrder2') }}：{{ item.orderNo || '' }}</div>
        </div>

        <div class="card-box flex">
            <!-- 图片区 -->
            <div class="img-box" v-if="item.orderTypeCode == 'POPULAR_DESTINATION_SERVICES'
                || item.orderTypeCode == 'TOURISM_PRODUCTS' || item.orderTypeCode == 'CAR_RENTAL'">
                <img class="imgs" :src="item.desImg" alt="">
            </div>
            <!-- 信息区 -->
            <div class="info-box flex1">
                <div class="title-box f-row-s-c">
                    <template v-if="item.orderTypeCode == 'POPULAR_DESTINATION_SERVICES'
                        || item.orderTypeCode == 'TOURISM_PRODUCTS' || item.orderTypeCode == 'CAR_RENTAL'">
                        <div class="titles one-txt-cut">{{ item.desName || '' }}</div>
                        <div class="types f-col-c-c">{{ customType[item.orderTypeCode] || '' }}</div>
                    </template>

                    <template v-if="item.orderTypeCode == 'SPECIAL_CAR_SERVICE'
                        || item.orderTypeCode == 'PICKUP_SERVICES' || item.orderTypeCode == 'SINGLE_CHARTER'">
                        <div class="address-box f-col-c-s">
                            <div class="address-item">
                                <span>{{ $t('Order3') }}</span>
                                {{ item.setOutAddress || '' }}
                            </div>
                            <div v-if="item.arriveOutAddress" class="address-item">
                                <span style="background: #7BBB7E;">{{ $t('Order5') }}</span>
                                {{ item.arriveOutAddress || '' }}
                            </div>
                            <div class="types address-types f-col-c-c">{{ customType[item.orderTypeCode] || '' }}</div>
                        </div>
                    </template>
                </div>
                <div class="user-box f-row-s-c">
                    <div class="f-row-s-c">
                        <img class="icons" src="../../public/img/order_avatar.png" alt="">
                        <div class="names">{{ item.name || '' }}</div>
                        <div class="phones">{{ item.phone || '' }}</div>
                    </div>
                    <div v-if="item.beName" class="f-row-s-c" style="margin-left: 10px;">
                        <img class="icons" src="../../public/img/order_avatar.png" alt="">
                        <div class="names">{{ $t('Order30') }}：{{ item.beName || '' }}</div>
                        <div class="phones">{{ item.bePhone || '' }}</div>
                        <div class="phones">{{ item.beEmail || '' }}</div>
                    </div>
                </div>
                <ul class="ul-box f-row-s-c f-w" :style="item.desImg ? 'width:520px;' : ''">
                    <li class="f-row-s-c">
                        <div class="labels">{{ $t('MOrder3') }}：</div>
                        <div class="values">{{ item.appointmentTime || '' }}</div>
                    </li>
                    <li class="f-row-s-c" v-if="item.orderTypeCode == 'SPECIAL_CAR_SERVICE'
                        || item.orderTypeCode == 'PICKUP_SERVICES' || item.orderTypeCode == 'SINGLE_CHARTER'">
                        <div class="labels">{{ $t('Order23') }}：</div>
                        <div class="values">{{ item.carTypeName || '' }}</div>
                    </li>
                    <li class="f-row-s-c" v-if="item.orderTypeCode == 'SPECIAL_CAR_SERVICE'
                        || item.orderTypeCode == 'PICKUP_SERVICES' || item.orderTypeCode == 'SINGLE_CHARTER'">
                        <div class="labels">{{ $t('MOrder4') }}：</div>
                        <div class="values f-row-e-c">
                            <span v-if="item.theBaby">{{ $t('MOrder7') }} ×{{ item.theBaby }}{{ item.carServiceVoList ? ','
                                :
                                '' }}</span>
                            <div v-for="(item, index) in item.carServiceVoList" :key="index">
                                <span>{{ item.serviceName || '' }},</span>
                            </div>
                            <!-- <span v-if="item.theRaising == 2">{{$t('MOrder8')}}{{ item.theBaby ? '，' : '' }}</span>
                            <span v-if="item.songRaising == 2">{{$t('MOrder9')}}</span> -->
                        </div>
                    </li>
                    <li class="f-row-s-c" v-if="item.orderTypeCode == 'PICKUP_SERVICES'">
                        <div class="labels">{{ $t('Order22') }}：</div>
                        <div class="values">{{ item.airplaneNo || '' }}</div>
                    </li>
                    <li class="f-row-s-c" v-if="item.orderTypeCode == 'SINGLE_CHARTER'">
                        <div class="labels">{{ $t('Order9') }}：</div>
                        <div class="values">{{ item.vehicleDuration ? item.vehicleDuration + 'H' : '' }}</div>
                    </li>
                    <li v-if="item.orderTypeCode == 'CAR_RENTAL'" class="f-row-s-c">
                        <div class="labels">{{ $t('Order37') }}：</div>
                        <div class="values">{{ item.rentNum || '0' }}{{ $t('Order45') }}</div>
                    </li>
                    <li v-if="item.orderTypeCode == 'POPULAR_DESTINATION_SERVICES' || item.orderTypeCode == 'TOURISM_PRODUCTS'"
                        class="f-row-s-c">
                        <div class="labels">{{ $t('Order53') }}：</div>
                        <div class="values">{{ item.spcNum || '0' }}</div>
                    </li>
                    <li v-if="item.orderTypeCode == 'POPULAR_DESTINATION_SERVICES' || item.orderTypeCode == 'TOURISM_PRODUCTS'"
                        class="f-row-s-c">
                        <div class="labels">{{ $t('Order56') }}：</div>
                        <div class="values">{{ item.spcName || '' }}</div>
                    </li>
                    <li class="f-row-s-c">
                        <div class="labels">{{ $t('MOrder5') }}：</div>
                        <div v-if="item.orderTypeCode == 'CAR_RENTAL'" class="values">￥{{ item.orderPrice || '0.00'
                        }}/{{ $t('Order45') }}</div>
                        <div v-else class="values">
                            <template v-if="$i18n.locale == 'en'">
                                <span>${{ item.orderAUDPrice || '0.00' }}</span>
                            </template>
                            <template v-else>
                                <span>￥{{ item.orderPrice || '0.00' }}</span>
                            </template>
                        </div>
                    </li>
                    <li class="f-row-s-c">
                        <div class="labels">{{ $t('MOrder6') }}：</div>
                        <div class="values">
                            <template v-if="$i18n.locale == 'en'">
                                <span>-${{ item.vipAUDDiscount || '0.00' }}</span>
                            </template>
                            <template v-else>
                                <span>-￥{{ item.vipDiscount || '0.00' }}</span>
                            </template>
                        </div>
                    </li>
                    <li class="f-row-s-c">
                        <div class="labels">{{ $t('Order47') }}：</div>
                        <div class="values">
                            <template v-if="$i18n.locale == 'en'">
                                <span>-${{ item.couponAUDMoney || '0.00' }}</span>
                            </template>
                            <template v-else>
                                <span>-￥{{ item.couponMoney || '0.00' }}</span>
                            </template>
                        </div>
                    </li>
                </ul>
                <div class="price-box">
                    <span v-if="$i18n.locale == 'en'">
                        $<span style="font-size: 20px;">{{ item.sfAUDMoney || '0.00' }}</span> ≈
                    </span>
                    <span>
                        ￥<span style="font-size: 20px;">{{ item.sfMoney || '0.00' }}</span>
                    </span>
                </div>
            </div>
            <!-- 操作区 -->
            <div class="btn-box">
                <slot></slot>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'VOrder',
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            customType: {
                'POPULAR_DESTINATION_SERVICES': this.$t('OT1'),
                'TOURISM_PRODUCTS': this.$t('OT2'),
                'CAR_RENTAL': this.$t('OT3'),
                'SPECIAL_CAR_SERVICE': this.$t('OT4'),
                'PICKUP_SERVICES': this.$t('OT5'),
                'SINGLE_CHARTER': this.$t('OT6'),
                'PERSONAL_TAILOR': this.$t('OT7'),
                // 'TOURISM_PRODUCTS': this.$t('Order27'),
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.top-box {
    padding: 0 12px;
    height: 50px;
    font-size: 14px;
    color: #333;
    background: #f2f3f4;
    border-bottom: 1px solid #e6e6e6;

    .time {
        margin-right: 48px;
    }
}

.card-box {
    position: relative;
    padding: 12px;
}

.img-box {
    margin-right: 22px;

    .imgs {
        width: 126px;
        height: 104px;
        object-fit: cover;
    }
}

.title-box {
    margin-bottom: 10px;

    .titles {
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }

    .types {
        margin-left: 8px;
        padding: 0 3px;
        min-width: 66px;
        height: 16px;
        font-size: 12px;
        color: #fff;
        background: var(--theme-color);
    }

    .address-item {
        line-height: 30px;
        font-size: 16px;
        color: #333;

        span {
            float: left;
            margin: 5px 6px 0 0;
            padding: 0 3px;
            min-width: 50px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            color: #fff;
            text-align: center;
            background: #2db7ac;
            border-radius: 2px;
        }
    }

    .address-types {
        margin: 10px 0 0;
        height: 24px;
    }
}

.user-box {
    margin-bottom: 10px;

    .icons {
        margin: 2px 6px 0 0;
        width: 18px;
        height: 18px;
        border-radius: 9px;
    }

    .names {
        margin-right: 6px;
        font-size: 13px;
        color: #333;
    }

    .phones {
        font-size: 13px;
        color: #666;
    }
}

.ul-box {
    margin-bottom: 10px;
    width: 660px;
    line-height: 26px;

    li {
        margin-right: 20px;

        .labels {
            font-size: 14px;
            color: #666;
        }

        .values {
            font-size: 14px;
            color: #333;
        }
    }
}

.price-box {
    font-weight: bold;
    font-size: 14px;
    color: var(--price-color);
}

.btn-box {
    position: absolute;
    right: 12px;
    bottom: 12px;
}
</style>