<template>
    <div class="page-box">
        <!-- 用户区 -->
        <div class="user-box f-col-c-c">
            <img class="avater" :src="userInfo.avatar || require('../../public/img/avatar.png')" alt="">
            <div class="vips f-row-c-c">
                <!-- <img src="../../public/img/vip.png" alt=""> -->
                <span class="txts">{{ userInfo.userGrade || '' }}</span>
            </div>
            <div class="names f-row-c-c" @click="editUserInfo">
                {{ userInfo.nickname || $t('My1') }}
                <img src="../../public/img/edit.png" style="width:12px;height:12px;" alt="">
            </div>
            <div class="phones">{{ userInfo.mobile || '' }}</div>
            <!-- <div class="contacts f-row-c-c" @click="openContact">
                <img src="../../public/img/contact.png" style="width:21px;" alt="">
                <span>{{ $t('My2') }}</span>
            </div> -->
        </div>

        <!-- 导航区 -->
        <ul class="ul-box">
            <li class="f-row-s-c" v-for="(item, index) in navList" :key="index"
                :class="{ 'active': $route.path == item.url }" @click="navBar(item)">
                <div class="img-box f-row-c-c">
                    <img class="imgs" :src="$route.path == item.url ? item.selectImg : item.img" alt="">
                </div>
                <span>{{ item.name }}</span>
            </li>
        </ul>

        <!-- 在线客服弹窗 -->
        <!-- <el-dialog :visible.sync="isShow" width="500px" :lock-scroll="false" :show-close="false" @close="closeDialog">
            <div class="dialog-top f-row-b-c" slot="title">
                <span class="titles">{{ $t('My2') }}</span>
                <i class="closes el-icon-close" @click="closeDialog"></i>
            </div>
            <div class="dialog-con f-col-c-c">
                <img :src="platCode" class="imgs">
                <p style="margin-top: 10px;">扫码添加微信，在线联系客服</p>
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'VSidebar',
    data() {
        return {
            active: 1,
            userInfo: {},

            navList: [
                {
                    id: 1,
                    name: this.$t('MNav1'),
                    url: '/centre/order',
                    img: require('../../public/img/side_grey1.png'),
                    selectImg: require('../../public/img/side1.png')
                },
                {
                    id: 2,
                    name: this.$t('MNav2'),
                    url: '/centre/member',
                    img: require('../../public/img/side_grey2.png'),
                    selectImg: require('../../public/img/side2.png')
                },
                {
                    id: 3,
                    name: this.$t('MNav3'),
                    url: '/centre/discount',
                    img: require('../../public/img/side_grey3.png'),
                    selectImg: require('../../public/img/side3.png')
                },
                {
                    id: 4,
                    name: this.$t('MNav4'),
                    url: '/centre/evaluate',
                    img: require('../../public/img/side_grey4.png'),
                    selectImg: require('../../public/img/side4.png')
                },
                {
                    id: 5,
                    name: this.$t('MNav5'),
                    url: '/centre/promise',
                    img: require('../../public/img/side_grey5.png'),
                    selectImg: require('../../public/img/side5.png')
                },
                {
                    id: 6,
                    name: this.$t('MNav6'),
                    url: '/centre/about',
                    img: require('../../public/img/side_grey6.png'),
                    selectImg: require('../../public/img/side6.png')
                },
                {
                    id: 7,
                    name: this.$t('MNav7'),
                    url: '/centre/set',
                    img: require('../../public/img/side_grey7.png'),
                    selectImg: require('../../public/img/side7.png')
                },
                {
                    id: 8,
                    name: this.$t('MNav8'),
                    url: '/centre/complaint',
                    img: require('../../public/img/side_grey8.png'),
                    selectImg: require('../../public/img/side8.png')
                },
                // {
                //     id: 9,
                //     name: this.$t('MNav9'),
                //     url: '/centre/setMessage',
                //     img: require('../../public/img/side_grey8.png'),
                //     selectImg: require('../../public/img/side8.png')
                // },
            ],
        }
    },
    created() {
        this.getData()
    },
    mounted() {
        this.active = sessionStorage.getItem('ACTIVE_MENU_PERSON');
    },
    methods: {
        //获取个人信息
        getData() {
            $api.getHighImportInfo().then(res => {
                if (res.code == 200) {
                    this.userInfo = res.data || {}
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //导航
        navBar(item) {
            this.active = item.id;
            sessionStorage.setItem('ACTIVE_MENU_PERSON', item.id);
            this.$router.push(item.url, () => { }, () => { })
        },
        //编辑用户信息
        editUserInfo() {
            if (this.$route.path == '/centre/setMessage') {
                return
            }
            this.$router.push('/centre/setMessage')
        },

        //打开客服弹窗
        openContact() {
            this.isShow = true
        },

        //关闭客服弹窗
        closeDialog() {
            this.isShow = false
        },
    }
}
</script>

<style lang="scss" scoped>
.page-box {
    flex: 0 0 230px;
    margin-right: 15px;
    width: 230px;
}

.user-box {
    padding: 12px 0 38px;
    background: url('../../public/img/side_bg.png') no-repeat center center/cover;
    border-radius: 4px 4px 0 0;

    .avater {
        width: 110px;
        height: 110px;
        border-radius: 100%;
        background: #ddd;
    }

    .vips {
        margin-top: -14px;
        padding: 0 10px;
        height: 18px;
        background: #fef0c1;
        border-radius: 8px;

        .txts {
            font-size: 12px;
            color: #ef9c18;
        }
    }

    .names {
        margin: 15px 0;
        font-weight: bold;
        font-size: 20px;
        color: #333;
        cursor: pointer;
    }

    .phones {
        font-size: 14px;
        color: #333;
    }

    .contacts {
        padding: 0 3px;
        min-width: 132px;
        height: 44px;
        font-size: 20px;
        color: #fff;
        background: var(--theme-color);
        border-radius: 4px;
        cursor: pointer;
    }
}

.ul-box {
    padding: 14px 0;
    background: #fff;
    border-radius: 4px;

    li {
        padding: 10px 0 10px 32px;
        min-height: 50px;
        line-height: 30px;
        font-size: 16px;
        color: #333;
        cursor: pointer;
        user-select: none;

        &.active {
            padding-left: 28px;
            color: var(--theme-color);
            background: #f2f3f5;
            border-left: 4px solid var(--theme-color);
        }

        .img-box {
            width: 20px;
            height: 20px;

            .imgs {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
            }
        }

        span {
            margin-left: 24px;
        }
    }
}

//弹窗
.dialog-con {
    min-height: 200px;

    img {
        margin: auto;
        width: 200px;
        height: 200px;
    }
}
</style>