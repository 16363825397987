import Vue from "vue";


import VHeader from "@/components/VHeader.vue"
import VhomeFoot from '@/components/VhomeFoot.vue'
import VhomeVlog from "@/components/VhomeVlog.vue"
import VhomeForm from '@/components/VhomeForm.vue'
import VSidebar from "@/components/VSidebar.vue"
import VvlogItem from "@/components/VvlogItem.vue"
import VCard from "@/components/VCard.vue"
import VCar from '@/components/VCar.vue'
import VOrder from "@/components/VOrder.vue"
import VAddress from "@/components/VAddress.vue"
import VTitle from '@/components/VTitle.vue'

import VSwiper from '@/components/swiper/VSwiper'
import VSwiperSlide from '@/components/swiper/VSwiperSlide'

import VorderDialog from '@/components/VorderDialog.vue'
import VpayDialog from '@/components/VpayDialog.vue'
import FloatBtn from "@/components/FloatBtn.vue"


Vue.component('VHeader', VHeader);
Vue.component('VhomeFoot', VhomeFoot);
Vue.component('VhomeVlog', VhomeVlog);
Vue.component('VhomeForm', VhomeForm);
Vue.component('VSidebar', VSidebar);
Vue.component('VvlogItem', VvlogItem);
Vue.component('VCard', VCard);
Vue.component('VCar', VCar);
Vue.component('VOrder', VOrder);
Vue.component('VAddress', VAddress);
Vue.component('VTitle', VTitle);

Vue.component('VSwiper', VSwiper);
Vue.component('VSwiperSlide', VSwiperSlide);

Vue.component('VorderDialog', VorderDialog);
Vue.component('VpayDialog', VpayDialog);
Vue.component('FloatBtn', FloatBtn);